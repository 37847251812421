<template>
  <div id="steps_container">
    <div class="steps">
      <template v-for="(step, index) in steps">
        <div v-if="!alreadySigned || (alreadySigned && ![1,4].includes(index))" :key="index" class="step-item is-info" :class="{ 'is-active': (computedStepCount - 1) === index, 'is-completed': (computedStepCount - 1) > index}">
          <div class="step-marker">
              {{ alreadySigned && index > 1 ? (index) : (index + 1) }}
          </div>
          <div class="step-details is-size-6" :class="{ 'is_blue': (computedStepCount - 1) === index}">
            <p>{{ $t('message.steps.'+step) }}</p>
          </div>
        </div>
      </template>
    </div>
  </div>

</template>

<script>

  export default {
    props: ['stepCount', 'complete', 'alreadySigned', 'zzp', 'intern'],

    data() {
      return {
        steps: [
          "personal",
          "contract",
          "id",
          "sign"
        ]
      }
    },

    created() {
      if (this.$showStippPensionStep && !this.intern) {
        this.steps.splice(2, 0, "pension")
      }

      if(this.zzp){
          this.steps = ["personal", "zzp", "sign"]
        }
    },

    computed: {
      computedStepCount() {
        let stepCount = this.stepCount

        if (stepCount > 2 && (!this.$showStippPensionStep || this.intern)) {
          stepCount--;
        }

        return stepCount
      }
    }
  }
</script>

<style lang="scss" scoped>
  .step-progress__step span {
    font-weight: 100;
  }
  #steps_container {
    justify-content: center;
    text-align: -webkit-center;
    .steps {
      margin: 50px 0px;
      max-width: 629px;
      .color_primary {
          color: #2495df;
      }
    }
  }
  .italic {
    font-style: italic;
  }
</style>
